import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recetas-platos',
  templateUrl: './recetas-platos.component.html',
  styleUrls: ['./recetas-platos.component.scss']
})
export class RecetasPlatosComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
