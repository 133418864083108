export class Empresas {

    id: number;
    nit: string; // no se actualiza
    direccion: string;
    nombre: string;
    telefono: string;
    logo: string;
    ciudad: string;
    descripcion: string;
    correo: string;
    regimen: string;
    tipoNegcio: number;
    tipoNegocio: number;
}
