import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Routing


  const MainRoutes: Routes = [
    
  ];


  export const appRoutingProviders: any[] = [];
  //export const routing: ModuleWithProviders = RouterModule.forRoot(MainRoutes);
