export class Compra {

      id: number;
      idProducto: number;
      idInsumo: number;
      medida: string;
      valorUnitario: number;
      cantidad: number;
      totalUnidades: number;
    
      producto: string;
      insumo: string;
      fecha: string;
      numero: string;
      empleado: string;
}
