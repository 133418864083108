export class Proveedor {
    idProveedor: number;
    nombre: string;
    telefono: string;
    direccion: string;

    contacto: string; 
    identificacion: string; 
    tipoIdentificacion: string; 
    regimen: string;
    correo: string;
}
