import { Zona } from './zona';

export class Restaurante {

     id: number; 
     nombre: string;
     direccion: string;
     idEmpresa: number;

     listaZonas: Array<Zona>;
}
