export class Reservas {
    id: number;
cantidadPersonas: number;
horas: number;
estado: number;
idMesa: number;
idCliente: number;
fechaReserva: string;
cliente: string;
mesa: String;
}
