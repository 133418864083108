export class AperturasCaja {
    id:number;
    fechaHoraApertura: string;
    fechaHoraCierre: string;
    efectivoApertura: number;
    efectivoCierre: number;
    totalVentas: number;
    totalDatafono: number;
    totalImpoconsumo: number;
    codigosFacturasDescuento: any;
    codigosFacturasAnuladas: any;
    cantidadMoviemientosSalida: number;
    cantidadMovimientosEntrada: number;
    estado: string;
    caja: number;
    empleadoAbre: number;
    empleadoCierra: number;
}
