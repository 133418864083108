export class PermisoRol {

    idPermiso: number;
    idRol: number;

    constructor(idP:number, idR:number){

        this.idPermiso = idP
        this.idRol = idR
    }
}
