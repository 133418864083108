export class MediosDePago {

    id: number;
    descripcion: string;
}



export class listaMediosDePago {

    tjCredito: number = 0;
    tjDebito: number = 0;
    efectivo: number = 0;
    cxc: number;
    cortessia;
    cheque: number = 0;
    tarjeta: number = 0;

}

