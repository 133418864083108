export class DetalleFactura {
    id:number;
    idFactura: number;
    idProducto: number;
    precioProducto: number;
    total: number;
    cantidad: number;
    fecha: string;
    nombre: string;
}
