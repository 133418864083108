export class CuadreCaja {

    cantMoneda50: number;
    cantMoneda200: number;
    cantMoneda100: number;
    cantMoneda500: number;
    cantMoneda1000: number;

    cantBill1000: number;
    cantBill2000: number;
    cantBill5000: number;
    cantBill10000: number;
    cantBill20000: number;
    cantBill50000: number;
    cantBill100000: number;
}
