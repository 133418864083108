export class Empleados {

      id: number;
      idEmpresa: number; // no se actualiza
      nombre: string;
      telefono: string;
      email: string;
      tipoIdentificacion: string;
      rol: string;
      cedula: string;

      idUsuario: number; // no se inserta ni actualiza
      identificacion: string;
      direccion: string;
}
