export class Compras {

    id: number;
    idProducto: number;
    idInsumo: number;
    medida;
    valor: number;
    valorUnitario: number;
    cantidad: number;
    totalUnidades: number;
    idUnidadMedida: number;
    cantUnidadesMedida: number;
    
    producto: string;
    insumo: string;
    fecha: string;
    numero: string;
    empleado: string;
}
