export class Cliente {
    id: number;
    nombre: string;
    celular: string;
    direccion: string;
    identificacion: any;
    tipoIdentificacion: string;
    fechaNacimiento: string;
    idCliente: any;
}

export class nuevoCliente {
    idCliente: number;
    id: number;
    nombre: string;
    celular: string;
    direccion: string;
    identificacion: any;
    tipoIdentificacion: string;
    fechaNacimiento: string;
}
